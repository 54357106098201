.invoice-generator {
    background: white;
    padding: 20px;
    border-radius: 8px;
    margin: 20px 0;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.invoice-controls {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.invoice-controls select,
.invoice-controls input,
.invoice-controls button {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.invoice-controls input[type="number"] {
    width: 100px;
}

.invoice-controls button {
    background: #4ECDC4;
    color: white;
    border: none;
    cursor: pointer;
}

.invoice-controls button:hover {
    background: #45B7D1;
}

.invoice-list {
    border-top: 1px solid #eee;
    padding-top: 20px;
}

.invoice-item {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 10px;
    border-bottom: 1px solid #eee;
    grid-template-columns: auto auto auto auto auto 1fr; /* Updated to accommodate the new date range column */
}

.invoice-item span {
    min-width: 100px;
}

.invoice-item button {
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.invoice-item button:first-of-type {
    background: #4ECDC4;
    color: white;
}

.invoice-item button:last-of-type {
    background: #45B7D1;
    color: white;
}

/* Invoice Print Styles */
.invoice-print-container { 
    font-family: Arial, sans-serif; 
    margin: 40px;
    font-size: 0.9em;
}

.invoice-header { 
    display: flex; 
    justify-content: space-between; 
    margin-bottom: 40px;
    font-size: 0.9em;
}

.invoice-header h1 {
    font-size: 1.8em;
}

.invoice-header h2 {
    font-size: 1.4em;
}

.main-content {
    display: grid;
    grid-template-columns: auto 300px;
    gap: 40px;
    margin-bottom: 30px;
}

.bill-to {
    font-size: 0.9em;
}

.payment-details {
    font-size: 0.9em;
    background: #f8f9fa;
    padding: 15px;
    border-radius: 4px;
    border: 1px solid #ddd;
}

table { 
    width: 100%; 
    border-collapse: collapse; 
    margin: 20px 0;
    font-size: 0.9em;
}

th, td { 
    padding: 10px; 
    text-align: left; 
    border-bottom: 1px solid #ddd; 
}

.table-footer {
    font-weight: bold;
    background-color: #f8f9fa;
}

.no-print {
    margin-bottom: 20px;
    padding: 10px 20px;
    background: #4ECDC4;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

@media print {
    .no-print {
        display: none;
    }
}

.delete-button {
    background-color: #ff4444;
    color: white;
}

.delete-button:hover {
    background-color: #cc0000;
}

/* Calendar Styles */
.work-calendar {
    margin: 40px 0;
    page-break-inside: avoid;
}

.calendars-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: flex-start;
}

.calendar-month {
    flex: 0 0 auto;
    margin-bottom: 20px;
}

.calendar-month h4 {
    margin: 0 0 10px 0;
    text-align: center;
}

.calendar {
    border-collapse: collapse;
    width: auto;
    margin: 0;
}

.calendar th,
.calendar td {
    width: 30px;
    height: 30px;
    text-align: center;
    font-size: 12px;
    padding: 5px;
    border: 1px solid #ddd;
}

.calendar th {
    background-color: #f8f9fa;
    font-weight: normal;
}

.worked-day {
    background-color: #4ECDC4;
    color: white;
    font-weight: bold;
}

@media print {
    .work-calendar {
        break-inside: avoid;
    }
    
    .calendar th,
    .calendar td {
        border: 1px solid #999;
    }
    
    .worked-day {
        background-color: #ccc !important;
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
    }
}

.gst-checkbox {
    display: flex;
    align-items: center;
    gap: 5px;
}

.gst-checkbox input[type="checkbox"] {
    margin: 0;
}

.total-row {
    font-size: 1.1em;
    background-color: #e9f7f6;
}
