@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

.rates-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    max-width: 1200px;
    margin: 0 auto;
    font-family: 'Montserrat', sans-serif;
}

.calendar-section {
    display: flex;
    gap: 30px;
    margin-bottom: 30px;
}

/* Make calendar bigger */
.react-calendar {
    width: 800px !important;
    padding: 20px;
    border-radius: 10px;
    border: none !important;
    box-shadow: 0 4px 12px rgba(0,0,0,0.15);
}

.react-calendar__tile {
    height: 80px !important;
    padding: 20px 5px !important;
}

.tile-content {
    font-size: 12px;
    padding: 4px;
    border-radius: 4px;
    color: white;
    display: flex;
    flex-direction: column;
    font-size: 10px;
    min-height: 40px;  /* Add minimum height */
    justify-content: space-between;
}

.invoice-badge {
    background: rgba(255, 255, 255, 0.9);
    border-radius: 3px;
    padding: 2px 4px;
    margin-top: 4px;
    font-size: 8px;
    font-weight: bold;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px solid currentColor;
}

.legend {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0,0,0,0.15);
}

.legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.color-box {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border-radius: 4px;
}

h1 {
    margin-bottom: 20px;
}

.company-buttons {
    display: flex;
    gap: 10px;
    margin: 20px 0;
}

.company-buttons button {
    margin: 5px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: opacity 0.2s;
}

.company-buttons button:hover {
    background-color: #0056b3;
    opacity: 0.8;
}

.assignment {
    background-color: #007bff;
    color: white;
    padding: 5px;
    border-radius: 3px;
    text-align: center;
}

.stats-section {
    background: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0,0,0,0.1);
    width: 100%;  /* Make it full width */
    max-width: 1200px;  /* Match container max-width */
    margin: 30px auto 0;
}

.stats-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    margin-bottom: 30px;
}

.stat-box {
    background: #f8f9fa;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
}

.stat-box h3 {
    margin: 0 0 10px 0;
    font-size: 16px;
    color: #666;
}

.stat-box p {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

.chart-container {
    height: 300px;
    margin-top: 20px;
}

.dashboard-title {
    font-size: 3.5rem;
    font-weight: 700;
    color: #2c3e50;
    text-align: center;
    margin: 1rem 0 3rem 0;
    padding: 1.5rem;
    text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.15);
    letter-spacing: 1px;
    background: #2c3e50;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
}

.dashboard-title::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 4px;
    background: linear-gradient(45deg, #2c3e50, #3498db);
    border-radius: 2px;
}

.view-toggle {
    margin-bottom: 2rem;
    display: flex;
    gap: 1rem;
}

.toggle-btn {
    padding: 0.8rem 1.5rem;
    border: 2px solid #2c3e50;
    background: none;
    color: #2c3e50;
    border-radius: 25px;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    transition: all 0.3s ease;
}

.toggle-btn.active {
    background: #2c3e50;
    color: white;
}

.yearly-view {
    width: 100%;
    max-width: 1200px;
}

.months-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
    padding: 1rem;
}

.month-card {
    background: white;
    border-radius: 10px;
    padding: 1.5rem;
    box-shadow: 0 0 20px rgba(0,0,0,0.1);
}

.month-card h3 {
    margin: 0 0 1rem 0;
    color: #2c3e50;
}

.month-card .revenue {
    font-size: 1.5rem;
    font-weight: bold;
    color: #2c3e50;
    margin: 0.5rem 0;
}

.company-days {
    margin-top: 1rem;
}

.company-day-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
}

.company-dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: inline-block;
}

.notes-container {
    margin: 20px 0;
    width: 100%;
}

.notes-container textarea {
    resize: vertical;
    min-height: 100px;
}
