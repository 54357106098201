.secret-santa-container {
    padding: 24px;
    background-image: url('https://images.unsplash.com/photo-1511131341194-24e2eeeebb09?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
  }
  .secret-santa-title {
    font-size: 2.5rem;
    font-weight: 550;
    margin-bottom: 32px;
    color: white;
  }
  
  .santa-list-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 24px;
    margin-bottom: 32px;
  }
  
  @media (max-width: 768px) {
    .category-selector {
      flex-direction: column;
      align-items: center;
    }
  
    .category-button {
      margin: 5px 0;
      width: 80%;
    }
  }
  
  
  .generate-button-container {
    text-align: center;
    margin-top: 24px;
  }
  
  .generate-button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 12px 24px;
    font-size: 1rem;
    border-radius: 24px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .generate-button:hover {
    transform: translateY(-2px) scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .generate-button-icon {
    margin-right: 8px;
  }
  
  .matches-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 24px;
    padding: 16px;
  }
  
  .matches-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #f44336;
    text-align: center;
    margin-bottom: 16px;
  }
  
  .matches-list {
    list-style-type: disc;
    padding-left: 24px;
  }
  
  .match-item {
    font-size: 1.1rem;
    margin-bottom: 8px;
  }
  
  .giver {
    font-weight: 600;
    color: #4caf50;
  }
  
  .receiver {
    font-weight: 600;
    color: #f44336;
  }

  /* Add these styles to your existing SecretSanta.css file */

.matches-section {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
  }
  
  .matches-card {
    flex: 1;
    margin-right: 24px;
  }
  
  .checklists-container {
    display: flex;
    flex: 1;
  }
  
  .checklist {
    flex: 1;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 16px;
    margin-left: 16px;
  }
  
  .checklist h3 {
    font-size: 1.2rem;
    color: #4caf50;
    margin-bottom: 16px;
  }
  
  .checklist-item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    cursor: pointer;
  }
  
  .checklist-item input[type="checkbox"] {
    display: none;
  }
  
  .check-icon {
    width: 20px;
    height: 20px;
    border: 2px solid #4caf50;
    border-radius: 4px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: transparent;
    transition: all 0.2s;
  }
  
  .check-icon.checked {
    background-color: #4caf50;
    color: white;
  }
  
  .reset-button {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 12px 24px;
    font-size: 1rem;
    border-radius: 24px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    margin-left: 16px;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .reset-button:hover {
    transform: translateY(-2px) scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .reset-button-icon {
    margin-right: 8px;
  }
  
  @media (max-width: 768px) {
    .matches-section {
      flex-direction: column;
    }
  
    .matches-card {
      margin-right: 0;
      margin-bottom: 24px;
    }
  
    .checklists-container {
      flex-direction: column;
    }
  
    .checklist {
      margin-left: 0;
      margin-top: 16px;
    }
  }


.matches-section {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
  }
  
  .matches-card {
    flex: 1;
    margin-right: 24px;
  }
  
  .checklists-container {
    display: flex;
    flex: 1;
  }
  
  .checklist {
    flex: 1;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 16px;
    margin-left: 16px;
  }
  
  .checklist h3 {
    font-size: 1.2rem;
    color: #4caf50;
    margin-bottom: 16px;
  }
  
  .checklist-item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    cursor: pointer;
  }
  
  .checklist-item input[type="checkbox"] {
    display: none;
  }
  
  .check-icon {
    width: 20px;
    height: 20px;
    border: 2px solid #4caf50;
    border-radius: 4px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: transparent;
    transition: all 0.2s;
  }
  
  .check-icon.checked {
    background-color: #4caf50;
    color: white;
  }
  
  .reset-button {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 12px 24px;
    font-size: 1rem;
    border-radius: 24px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    margin-left: 16px;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .reset-button:hover {
    transform: translateY(-2px) scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .reset-button-icon {
    margin-right: 8px;
  }
  
  @media (max-width: 768px) {
    .matches-section {
      flex-direction: column;
    }
  
    .matches-card {
      margin-right: 0;
      margin-bottom: 24px;
    }
  
    .checklists-container {
      flex-direction: column;
    }
  
    .checklist {
      margin-left: 0;
      margin-top: 16px;
    }
  }


  .category-selector {
    display: flex;
    justify-content: left;
    margin-bottom: 24px;
  }
  
  .category-button {
    background-color: #f0f0f0;
    border: none;
    padding: 10px 20px;
    margin: 0 10px;
    font-size: 1rem;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .category-button:hover {
    background-color: #e0e0e0;
  }
  
  .category-button.active {
    background-color: #4caf50;
    color: white;
  }

