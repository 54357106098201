.password-prompt-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.password-prompt-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    width: 90%;
    max-width: 400px;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 20px;
    cursor: pointer;
}

.password-prompt-content form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 20px 0;
}

.password-prompt-content input {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.password-prompt-content button {
    padding: 8px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.error-message {
    color: red;
    margin-top: 10px;
}

.data-display {
    margin-top: 20px;
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 4px;
    overflow-x: auto;
}

.data-display pre {
    margin: 0;
    white-space: pre-wrap;
}

.loading-message {
    color: #666;
    margin: 10px 0;
    font-style: italic;
}