.summary-stats {
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0,0,0,0.15);
    max-width: 1400px; /* increased from default */
    margin: 0 auto;
}

.stats-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.stats-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* show more items in a row */
    gap: 20px;
    margin-bottom: 30px;
}

.stat-box {
    padding: 20px;
    background: #f8f9fa;
    border-radius: 6px;
    text-align: center;
}

.weekly-breakdown {
    margin-top: 30px;
}

.weekly-table {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
}

.weekly-table th,
.weekly-table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: center;
}

.charts-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin: 20px 0;
}

.chart-container {
    margin: 20px 0;
    height: 400px; /* taller charts */
}

.company-breakdown {
    margin-top: 0;
}

.month-selector {
    padding: 8px 12px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: white;
    cursor: pointer;
    min-width: 200px;
}

.month-selector:hover {
    border-color: #999;
}

.month-selector:focus {
    outline: none;
    border-color: #4ECDC4;
    box-shadow: 0 0 0 2px rgba(78, 205, 196, 0.2);
}

.controls {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.view-toggle {
    padding: 0.5rem 1rem;
    border-radius: 4px;
    border: 1px solid #ddd;
    background-color: #fff;
    cursor: pointer;
}

.view-toggle:hover {
    background-color: #f5f5f5;
}

.date-selector {
    /* ...existing select styles... */
}

.tax-calculator {
    margin-top: 2rem;
    padding: 1.5rem;
    background-color: #f8f9fa;
    border-radius: 8px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 2rem;
}

.calculator-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.calculator-inputs {
    display: flex;
    gap: 1rem;
    margin: 1rem 0;
    grid-template-columns: repeat(2, 1fr);
}

.input-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.input-group input {
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100px;
}

.fy-selector {
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: white;
    min-width: 120px;
}

.calculator-results {
    display: grid;
    gap: 1rem;
    margin-top: 1.5rem;
    padding: 1rem;
    background-color: white;
    border-radius: 4px;
}

.result-item {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
    border-bottom: 1px solid #ddd;
}

.result-item:last-child {
    border-bottom: none;
    font-weight: bold;
}