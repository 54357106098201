@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');

/* Remove default margin and padding from body and html */
body, html {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
}

/* Override default border */
* {
  border: none !important;
}

/* Target root element */
#root {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  width: 100%;
}

